import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// components
import Layout from '../components/Layout';
import Feature from '../components/Pages/Feature';

// hooks
import useTranslated from '../hooks/useTranslated';

const AudienceAnalyticsComponent = ({ data, ...rest }) => {
    const { translatedData } = useTranslated(data, rest.pageContext);

    return (
        <Layout
            path={rest.location.pathname}
            lng={rest.pageContext.langKey}
            hasContainer={false}
            otherFeatures={translatedData.strapiAudienceAnalytics.otherFeatures}
            metatags={translatedData.strapiAudienceAnalytics.metatags}
            href="/audience-analytics/"
            showOurCustomers={false}
        >
            <Feature
                data={translatedData.strapiAudienceAnalytics}
                lng={rest.pageContext.langKey}
                featureComponentFirst
            />
        </Layout>
    );
};

AudienceAnalyticsComponent.propTypes = {
    data: PropTypes.shape({
        strapiAudienceAnalytics: PropTypes.shape({}).isRequired,
    }).isRequired,
};

export default AudienceAnalyticsComponent;

export const query = graphql`
    query AudienceAnalyticsEn {
        strapiAudienceAnalytics {
            metatags {
                description_de
                description_en
                title_de
                title_en
            }
            background {
                publicURL
            }
            button {
                href
                label_en
                label_de
            }
            description_en
            description_de
            feature {
                feature {
                    description_en
                    description_de
                    icon {
                        publicURL
                    }
                    id
                }
            }
            otherFeatures {
                id
                site_links {
                    href
                    iconDefault {
                        publicURL
                    }
                    description_en
                    description_de
                    iconHover {
                        publicURL
                    }
                    id
                    label_en
                    label_de
                }
                title_en
                title_de
            }
            sections {
                description_en
                description_de
                id
                image_de {
                    publicURL
                }
                image_en {
                    publicURL
                }
                title_en
                title_de
            }
            title_en
            title_de
        }
    }
`;
